import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import HTMLParser from 'html-react-parser';
import moment from 'moment';
import Masonry from 'react-masonry-css';
import Cookies from 'universal-cookie';
import Lightbox from '../../../Lightbox';

import NavHead from '../../../NavHead';
import SectionMedia from '../SectionMedia';
import LayoutContainer from '../LayoutContainer';
import imageHelper from '../../../Image/helper';
import Image from '../../../Image';
import { formColor, createPreviewPath } from '../../../../helper';
import '../../../../lightbox.css';
import styles from './styles.module.css';
import CookieWarning from '../../../CookieWarning';

const cookies = new Cookies();

class Article extends React.PureComponent {

  constructor(props) {

    super(props);

    cookies.get('cookiesAccepted');
    const { themeData, article, section } = this.props.pageContext;

    const images = [];
    if (article) {

      article.data.forEach(c => {

        if (c.type === 'GALLERY' && c.lightbox === true) {

          c.data.forEach(img => {

            images.push(img);

          });

        }

      });

    }

    let previewSrc;
    let img;

    if (article && article.image.id) img = article.image.id;
    else if (article
      && article.articleLayout.heroImage.id) img = article.articleLayout.heroImage.id;
    else if (props.pageContext.seo
      && props.pageContext.seo.defaultPreview) img = props.pageContext.seo.defaultPreview;

    let imgData;
    if (img) {

      props.pageContext.images.some(i => {

        let found = false;
        if (i._id.toString() === img || i.path === img) {

          imgData = i;
          found = true;

        }

        return found;

      });

    }

    if (imgData && imgData.path) previewSrc = createPreviewPath(imgData);

    const themeHeadingStyle = {
      fontFamily: themeData.typography.heading.name,
      fontWeight: themeData.typography.heading.weight,
      lineHeight: themeData.typography.heading.lineHeight,
      letterSpacing: themeData.typography.heading.letterSpacing,
    };

    const themeDefaultStyle = {
      fontFamily: themeData.typography.default.name,
      weight: themeData.typography.default.weight,
      lineHeight: themeData.typography.default.lineHeight,
      letterSpacing: themeData.typography.default.letterSpacing,
    };

    const themeNavigationStyle = {
      fontFamily: themeData.typography.navigation.name,
      fontWeight: themeData.typography.navigation.weight,
      lineHeight: themeData.typography.navigation.lineHeight,
      letterSpacing: themeData.typography.navigation.letterSpacing,
    };

    const color0 = {
      color: themeData.colors[0],
    };

    const color1 = {
      color: themeData.colors[1],
    };

    const color3 = {
      color: themeData.colors[3],
    };

    const nav = props.pageContext.navigation;
    const { overlay } = nav.data[0];

    const logoHeight = props.pageContext.logo && props.pageContext.logo.active
      && props.pageContext.logo.logoHeight !== undefined ? props.pageContext.logo.logoHeight : 60;

    this.state = {
      section,
      article,
      photoIndex: 0,
      isOpen: false,
      images,
      themeHeadingStyle,
      themeDefaultStyle,
      themeNavigationStyle,
      color0,
      color1,
      color3,
      overlay,
      isScrolling: false,
      matches: null,
      previewSrc,
      logoHeight,
    };

    this.sentinel = React.createRef();

    this.createText = this.createText.bind(this);
    this.createImage = this.createImage.bind(this);
    this.createVideo = this.createVideo.bind(this);
    this.createGallery = this.createGallery.bind(this);
    this.createHeader = this.createHeader.bind(this);
    this.createQuote = this.createQuote.bind(this);
    this.createAuthorBox = this.createAuthorBox.bind(this);
    this.handleImageClick = this.handleImageClick.bind(this);
    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.handleMoveNextRequest = this.handleMoveNextRequest.bind(this);
    this.handleMovePrevRequest = this.handleMovePrevRequest.bind(this);
    this.documentReady = this.documentReady.bind(this);
    this.updateMatches = this.updateMatches.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

  }

  componentDidMount() {

    const breakpoint = '769px';
    this.mediaQueryList = window.matchMedia(`(min-width: ${breakpoint})`);
    this.mediaQueryList.addListener(this.updateMatches);

    this.setState({
      matches: window.matchMedia(`(min-width: ${breakpoint})`).matches,
    });

    if (this.props.pageContext.navigation.data[0].fixed_top) {

      const elem = this.sentinel.current;
      if (elem) {

        const observer = new IntersectionObserver(this.handleScroll);
        observer.observe(elem);

      }

    }

  }

  componentWillUnmount() {

    if (this.mediaQueryList) {

      this.mediaQueryList.removeListener(this.updateMatches);

    }

  }

  updateMatches() {

    this.setState({
      matches: this.mediaQueryList.matches,
    });

  }

  handleScroll(entries) {

    if (this.props.pageContext.navigation.data[0].fixed_top) {

      this.setState({
        isScrolling: !entries[entries.length - 1].isIntersecting,
      });

    }

  }

  createText(item, index, subIndex, quote) {

    const cols = quote === true ? '' : 'col-10 col-lg-8';
    const id = `${this.state.section._id}_P_${subIndex !== undefined ? subIndex : ''}${index}_section`;
    let content;
    let styleNames;
    if (item.type === 'HEADINGS/HEADING-TWO') {

      content = (
        <h2
          style={{ color: '#000000' }}
          className={styles[`Title3${this.props.pageContext.themeData.typography.heading.fontSize}`]}
        >
          { HTMLParser(item.text) }
        </h2>
      );
      styleNames = styles.articleText;

    } else if (item.type.startsWith('PARAGRAPH')) {

      content = (<span>{ HTMLParser(item.text) }</span>);
      styleNames = quote === true ? undefined : styles.articleText;

    }

    const text = (
      <div>
        { content }
      </div>
    );

    const elem = (
      <div
        id={id}
        key={id}
        className={`${cols} ${styleNames}`}
      >
        { text }
      </div>
    );

    return elem;

  }

  createImage(item, index, subIndex, cols, galleryIndex, normal, lightbox) {

    const wrapper = `articleImageWrapper${item.icon ? 'Icon' : ''}`;
    let imgWrapper = 'imageContent5';
    let img = 'imageFull';
    if (normal === true) {

      imgWrapper = 'imageWrapper100';
      img = 'galleryImg';

    }
    const sizes = cols === undefined ? '100vw' : `${100 / cols}vw`;

    const elem = (
      <div
        className={galleryIndex !== undefined ? undefined : 'col-10'}
        key={`${this.state.section._id}_Image_${galleryIndex !== undefined ? galleryIndex : ''}${subIndex !== undefined ? subIndex : ''}${index}_section`}
      >
        <div className={styles[wrapper]} style={lightbox ? { cursor: 'pointer' } : undefined}>
          <SectionMedia
            mediaType={item.icon ? 'ICON' : 'IMAGE'}
            wrapperStyle={imgWrapper}
            elementStyle={img}
            iconStyle=""
            sizes={sizes}
            onClick={lightbox ? this.handleImageClick : undefined}
            src={item.CDNLink
              ? item.CDNLink
              : `${process.env.IMAGES_CDN}/${item.src}`}
            alt={item.alt}
            data={item}
            images={this.props.pageContext.images}
            pagePathList={this.props.pagePathList}
            articlePathList={this.props.articlePathList}
          />
        </div>
      </div>
    );

    return elem;

  }

  createVideo(item, index, subIndex) {

    let timeCode = Number(item.minutes * 60);
    timeCode += Number(item.seconds);
    const videoUrl = `https://www.youtube.com/embed/${item.videoID}?autoplay=false${item.enableTimeCode ? `&start=${timeCode}` : ''}`;
    const id = `${this.state.section._id}_Article_${index}_Content_Video_${subIndex}_section`;

    const video = (
      <div className="col-10" id={id} key={`${this.state.section._id}_Article_${index}_Video_${subIndex}`}>
        <iframe
          frameBorder="0"
          className={styles.videoIframeStyle}
          src={videoUrl}
          allowFullScreen
          aria-hidden="true"
        />
      </div>
    );

    return video;

  }

  createGallery(items, index, subIndex, lightbox) {

    const pictures = [];
    items.forEach((item, i) => (

      pictures.push(this.createImage(
        item,
        index,
        subIndex,
        Number(this.state.article.data[subIndex].columns),
        i,
        this.state.article.data[subIndex].crop,
        lightbox,
      ))

    ));

    let gallery;
    if (this.state.article.data[subIndex].crop !== true) {

      const breakpointColumnsObj = {
        default: Number(this.state.article.data[subIndex].columns),
        500: 1,
      };

      gallery = (
        <Masonry
          breakpointCols={breakpointColumnsObj}
          style={{ display: 'flex' }}
          className=""
          columnClassName=""
        >
          { pictures.map((pic, i) => <div key={`${this.state.section._id}_Gallery_Image${index}${subIndex}${i}`} className={styles.masonryImageWrapper}>{pic}</div>) }
        </Masonry>
      );

    } else {

      gallery = [];
      const cols = `col-12 col-md-${12 / Number(this.state.article.data[subIndex].columns)}`;
      pictures.forEach((p, i) => {

        let key;
        if (i < pictures.length - 1) {

          key = `${this.state.section._id}_Gallery_Image${index}${subIndex}${i}`;

        } else {

          key = `${this.state.section._id}_Gallery_AddBtnWrapper${index}${subIndex}`;

        }

        const pic = (
          <div
            className={cols}
            key={key}
          >
            {p}
          </div>
        );

        return gallery.push(pic);

      });

    }

    const id = `${this.state.section._id}_Article_${index}_Content_Gallery_${subIndex}_section`;
    const result = (
      <div key={`${this.state.section._id}_Gallery_${index}${subIndex}`} className="col-10" id={id}>
        <div className="row">
          { gallery }
        </div>
      </div>
    );

    return result;

  }

  createHeader() {

    const navHeight = this.state.logoHeight + (this.state.isScrolling
      && this.props.pageContext.navigation.data[0].fixed_top ? 8 : 80);

    const padding = {};
    padding.paddingTop = `${navHeight}px`;
    let color = { color: '#000000' };

    if (this.state.article.articleLayout.heroActive === true) {

      const top = this.state.overlay === true ? navHeight + 120 : 120;
      padding.paddingBottom = '80px';
      padding.paddingTop = `${top}px`;
      color = { color: '#ffffff' };

    }

    let pub;
    if (this.state.article.articleLayout.dateActive === true) {

      pub = moment(this.state.article.pubDate).format('D.M.YYYY');

    }

    let tags;
    if (this.state.article.articleLayout.tagsActive === true) {

      tags = [];
      this.state.article.tags.forEach(t => {

        const tag = (
          <span style={this.state.color0}>
            {t}
          </span>
        );
        tags.push(tag);

      });

    }

    let dateTag;
    if (pub !== undefined || tags !== undefined) {

      dateTag = (
        <div className={styles.dateTag} style={color}>
          {
            pub !== undefined
            && (
              <React.Fragment>
                <i className={`entypo icon-calendar ${styles.icon1}`} />
                <span>{ pub }</span>
              </React.Fragment>
            )
          }
          {
            tags !== undefined && tags.length > 0
            && (
              <React.Fragment>
                <i className={`entypo icon-mouse ${styles.icon2}`} />
                <span>
                  {
                    tags.map((tag, idx) => {

                      let text = ', ';
                      if (idx === tags.length - 1) {

                        text = '';

                      }

                      return <span key={`${this.state.section._id}_${this.state.article._id}_TagSpan_${this.state.article.tags[idx]}`}>{tag}{text}</span>;

                    })
                  }
                </span>
              </React.Fragment>
            )
          }
        </div>
      );

    }

    const size = this.state.article.articleLayout.heroImage.size || 'cover';
    const position = this.state.article.articleLayout.heroImage.position || 'center center';
    let id;
    if (this.state.article.articleLayout.heroActive === true) {

      if (
        this.state.article.articleLayout.articleImage
        && (
          this.state.article.image.id
          || this.state.article.image.CDNLink
        )
      ) ({ id } = this.state.article.image);
      else if (
        !this.state.article.articleLayout.articleImage
        && (
          this.state.article.articleLayout.heroImage.id
          || this.state.article.articleLayout.heroImage.CDNLink
        )
      ) ({ id } = this.state.article.articleLayout.heroImage);

    }

    let image;
    if (id !== undefined) {

      image = (
        <div className={styles.headerImageWrapper}>
          <Image
            id={id}
            sizes="100vw"
            size={size}
            position={position}
            alt="Background"
            imageClass="sectionBackgroundImageFull"
            images={this.props.pageContext.images}
          />
        </div>
      );

    }

    let overlay;
    if (
      this.state.article.articleLayout.overlay
      && (
        this.state.article.articleLayout.overlay.color
        || this.state.article.articleLayout.overlay.opacity >= 0
      )
    ) {

      const oColor = this.state.article.articleLayout.overlay.color || '#000';
      const oOpacity = this.state.article.articleLayout.overlay.opacity >= 0
        ? this.state.article.articleLayout.overlay.opacity
        : 0.7;

      overlay = formColor(
        { solid: oColor },
        undefined,
        oOpacity,
      );

    }

    const text = (
      <div className={`row ${styles.articleRow}`}>
        <div className={`col-${this.state.article.articleLayout.heroActive === true ? '10' : '8'}`}>
          { dateTag }
          <div className={styles.headerText}>
            <h1 style={{ ...color, ...{ fontSize: '2rem' } }}>
              { this.state.article.title }
            </h1>
          </div>
          {
            this.state.article.articleLayout.excerptActive === true
            && (
              <div className={styles.headerText}>
                    <span
                      className={styles[`Subtitle${this.props.pageContext.themeData.typography.heading.fontSize}`]}
                      style={{ ...this.state.themeHeadingStyle, ...{ fontWeight: 'normal', fontSize: '1.2rem' }, ...color }}
                    >
                      { HTMLParser(this.state.article.excerpt) }
                    </span>
              </div>
            )
          }
        </div>
      </div>
    );

    const header = (
      <div
        key={`${this.state.section._id}_${this.state.article._id}_Header`}
        className={`${styles.header} ${this.state.article.articleLayout.heroActive === true ? ` ${styles.center}` : ''}`}
        style={padding}
      >
        { image }
        {
          this.state.article.articleLayout.heroActive === true
          && (
            <div className={styles[`headerGradient${overlay ? 'Overlay' : ''}`]} style={overlay} />
          )
        }
        <div className={styles.headerContentWrapper}>
          <div className={`container ${styles.contentWrapper}`}>
            { text }
          </div>
        </div>
      </div>
    );

    return header;

  }

  createQuote(item, index, subIndex) {

    const quote = (
      <div className={`col-8 ${styles.quoteWrapper}`} key={`${this.state.section._id}_Article_${index}_Quote_${subIndex}`}>
        <div className={styles.quoteBar} style={{ backgroundColor: this.state.color0.color }} />
        <div>
          <div style={{ height: '10px' }} />
          { this.createText(item, index, subIndex, true) }
          <div style={{ height: '10px' }} />
        </div>
      </div>
    );

    return quote;

  }

  createAuthorBox(auth) {

    const author = this.state.section.data[this.state.section.data.length - 1]
      .authors.find(a => a._id === auth);

    let box;
    if (author !== undefined) {

      const links = [];
      if (author.links.length > 0) {

        author.links.forEach((l, i) => {

          if (l.active === true && l.image.icon !== null) {

            const link = (
              <a
                key={`${this.state.section._id}_ArticleMother_Owner_Link_${i}`}
                href={l.image.linkUrl}
                target={l.image.openLinkInNewTab ? '_blank' : '_self'}
                rel={l.image.openLinkInNewTab ? 'noopener noreferrer' : ''}
              >
                <i
                  className={`entypo ${l.image.icon.class}`}
                  aria-hidden="true"
                  style={{
                    fontSize: l.image.icon.size,
                    color: l.image.icon.color,
                    marginRight: '4px',
                  }}
                />
              </a>
            );

            links.push(link);

          }

          return null;

        });

      }

      box = (
        <div key={`${this.state.section._id}_Article_${this.state.article._id}_AuthorBox`} className={styles.authorBox}>
          <div className={styles.line} />
          <div className={`row ${styles.authorRow}`}>
            <div className="col-10 col-sm-3">
              {
                author.image !== ''
                && (
                  <div className={styles.authorImage}>
                    <SectionMedia
                      mediaType={author.image.icon ? 'ICON' : 'IMAGE'}
                      wrapperStyle="imageContent3"
                      elementStyle=""
                      iconStyle=""
                      sizes="25vw"
                      src={author.image.CDNLink
                        ? author.image.CDNLink
                        : `${process.env.IMAGES_CDN}/${author.image.src}`}
                      alt={author.image.alt}
                      data={author.image}
                      images={this.props.pageContext.images}
                      pagePathList={this.props.pagePathList}
                      articlePathList={this.props.articlePathList}
                    />
                  </div>
                )
              }
            </div>
            <div className="col-10 col-sm-5">
              {
                author.name !== ''
                && (
                  <div>
                    <h4 className={styles.authorText}>
                      {author.name}
                    </h4>
                  </div>
                )
              }
              {
                author.desc !== ''
                && (
                  <div>
                    <span className={styles.authorText}>
                      { HTMLParser(author.desc) }
                    </span>
                  </div>
                )
              }
              {
                links.length > 0
                && (
                  <div className={styles.linksWrapper}>
                    { links }
                  </div>
                )
              }
            </div>
          </div>
        </div>
      );

    }

    return box;

  }

  handleImageClick(event) {

    const photoIndex = this.state.images
      .findIndex(image => image.id === event.target.dataset.imageid);

    if (photoIndex > -1) {

      this.setState({
        photoIndex,
        isOpen: true,
      });

    }

  }

  handleCloseClick() {

    this.setState({
      isOpen: false,
    });

  }

  handleMoveNextRequest() {

    this.setState({
      photoIndex: (this.state.photoIndex + 1) % this.state.images.length,
    });

  }

  handleMovePrevRequest() {

    this.setState({
      photoIndex: (this.state.photoIndex + (this.state.images.length - 1))
        % this.state.images.length,
    });

  }

  documentReady(callbackFunction) {

    if (typeof document !== 'undefined') {

      if (document.readyState !== 'loading') {

        callbackFunction();

      } else {

        document.addEventListener('DOMContentLoaded', callbackFunction);

      }

    }

  }

  render() {

    let ready = false;
    if (typeof document !== 'undefined') {

      this.documentReady(() => {

        ready = true;

      });

    }

    let header;
    let article;
    if (this.state.section !== undefined && this.state.article !== undefined) {

      article = [];
      header = this.createHeader();

      this.state.article.data.forEach((item, index) => {

        if (index - 1 >= 0 && this.state.article.data[index - 1].type !== 'HEADINGS/HEADING-TWO') {

          const add = (
            <div key={`${this.state.section._id}_article_${this.state.article._id}_separator_${index}`} className={styles.separator} />
          );

          article.push(add);

        }

        let elem;
        if (item.type === 'HEADINGS/HEADING-TWO' || item.type === 'PARAGRAPH/PARAGRAPH') {

          elem = this.createText(item, undefined, index);

        } else if (item.type === 'PARAGRAPH/QUOTE') {

          elem = this.createQuote(item, undefined, index);

        } else if (item.type === 'IMAGE') {

          elem = this.createImage(item.content, undefined, index);

        } else if (item.type === 'VIDEO') {

          elem = this.createVideo(item.content, undefined, index);

        } else if (item.type === 'GALLERY') {

          elem = this.createGallery(item.data, undefined, index, item.lightbox);

        } else if (item.active === true) {

          elem = this.createAuthorBox(item.author);

        }

        article.push(elem);

      });

    }

    return (
      <React.Fragment>
        <div ref={this.sentinel} />
        <div id="modal-root" />
        <NavHead
          isScrolling={this.state.isScrolling}
          pageContext={this.props.pageContext}
          previewSrc={this.state.previewSrc}
          metaTitle={this.state.article ? this.state.article.metaTitle : undefined}
          metaDescription={this.state.article ? this.state.article.metaDescription : undefined}
          title={this.state.article ? this.state.article.title : undefined}
          desc={this.state.article ? this.state.article.excerpt : undefined}
        />
        <div>
          { header }
          <div className="container">
            {
              this.state.images.length > 0
              && (
                <Lightbox
                  mainSrc={imageHelper.getBestQuality(
                    this.state.images[this.state.photoIndex].id, this.props.pageContext.images,
                  )}
                  nextSrc={imageHelper.getBestQuality(
                    this.state.images[(this.state.photoIndex + 1) % this.state.images.length].id,
                    this.props.pageContext.images,
                  )}
                  prevSrc={imageHelper.getBestQuality(
                    this.state.images[
                      (this.state.photoIndex
                        + (this.state.images.length - 1)) % this.state.images.length].id,
                    this.props.pageContext.images,
                  )}
                  onCloseRequest={this.handleCloseClick}
                  onMovePrevRequest={this.handleMovePrevRequest}
                  onMoveNextRequest={this.handleMoveNextRequest}
                  visible={this.state.isOpen}
                />
              )
            }
            <div className={`row ${styles.articleRow}`}>
              { article }
            </div>
          </div>
        </div>
        {
          (
            this.props.pageContext.cookie
            && this.props.pageContext.cookie.active === true
            && cookies
            && cookies.cookies
            && cookies.cookies.cookiesAccepted === undefined
            && ready === true
          ) && (
            <CookieWarning
              colors={this.props.pageContext.themeData.colors}
              cookie={this.props.pageContext.cookie}
            />
          )
        }
        {
          this.props.pageContext.footer
          && (
            <LayoutContainer
              pageContext={this.props.pageContext}
              key={`Layout_${this.props.pageContext.footer._id}`}
              section={this.props.pageContext.footer}
              matches={this.state.matches}
            />
          )
        }
      </React.Fragment>
    );

  }

}

Article.propTypes = {
  section: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  article: PropTypes.shape({}),
};

export default CSSModules(
  Article, styles,
  { allowMultiple: true },
);
