import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import HTMLParser from 'html-react-parser';

import styles from './styles.module.css';

class QuoteLayout extends React.PureComponent {

  constructor(props) {

    super(props);

    const styleIndex = props.section.data.findIndex(elem => elem.type === 'STYLE');
    const align = styleIndex > -1 && props.section.data[styleIndex].align
      ? props.section.data[styleIndex].align : 'Left';
    const layout = styleIndex > -1 && props.section.data[styleIndex].layout
      ? props.section.data[styleIndex].layout : 'left';
    const full = styleIndex > -1 && props.section.data[styleIndex].full === true;

    this.state = {
      section: props.section,
      align,
      layout,
      full,
    };

  }

  render() {

    const paragraphAlignStyle = `quoteParagraph${this.state.align}`;
    const quoteExceptionStyle = `quoteException${this.props.themeData.typography.default.fontSize}`;
    const quoteAuthorExceptionStyle = `quoteAuthorException${this.props.themeData.typography.default.fontSize}`;

    const texts = [];
    this.props.section.data.forEach((item, index) => {

      if (item.active) {

        let text;
        if (item.type.startsWith('PARAGRAPH')) {

          if (index === 0) {

            text = (
              <span className={styles[quoteExceptionStyle]}>
                { HTMLParser(item.text) }
              </span>
            );

          } else if (index === 1) {

            text = (
              <span className={styles[quoteAuthorExceptionStyle]}>
                {HTMLParser(item.text)}
              </span>
            );

          }

        }

        if (text) {

          const content = (
            <div
              key={`${this.props.section._id}_layout_${index}`}
              className={styles[paragraphAlignStyle]}
            >
              { text }
            </div>
          );

          texts.push(content);

        }

      }

      return null;

    });

    const columns = this.state.layout === 'left' ? 'col-12 col-sm-10 offset-sm-1' : 'col-12 col-sm-6';
    const rowWrapper = this.state.layout === 'left' ? '' : `quoteRow${this.state.align}`;
    const container = this.state.layout === 'left' || (this.state.layout !== 'left' && !this.state.full) ? 'container' : undefined;

    const quote = this.state.layout === 'left'
      ? (
        <div className={columns}>
          { texts }
        </div>
      )
      : (
        <div className={`${columns} ${styles.quoteWrapper}`} style={this.state.layout === 'newLeft' ? this.props.boxStyle : undefined}>
          <div className={styles.quoteContentWrapper}>
            { texts }
          </div>
        </div>
      );

    return (
      <div className={container}>
        <div
          className={`row${rowWrapper ? ` ${styles[rowWrapper]}` : ''}`}
          style={!container ? { margin: 0 } : undefined}
        >
          { quote }
        </div>
      </div>
    );

  }

}

QuoteLayout.propTypes = {
  themeData: PropTypes.shape({}),
  section: PropTypes.shape({}),
};

export default CSSModules(
  QuoteLayout, styles,
  { allowMultiple: true },
);
